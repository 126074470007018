import { Box, Stack, IconButton, Typography, Tooltip } from "@mui/material";
import { GridSelectionModel} from "@mui/x-data-grid";
import DropZone from "../Dropzone";
import DeleteIcon from '@mui/icons-material/Delete';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useEffect, useState } from "react";
import SnackBar from "../Snackbar";
import { StripedDataGrid } from "../StripedDataGrid";
import { S3 } from '../../constants'
import { columns, fcsFileHandler, checkFileName } from "../../helpers/fcsFiles.helper"
import { httpClient } from "../..";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

type Props = {
  sampleId: string | null,
  incomingsBucket: string | undefined,
}

export default function FCSFiles({sampleId, incomingsBucket}: Props){

  const { t, i18n } = useTranslation();
  const [files, setFiles] = useState<Object[]>([])
  const [selectedFiles, setSelectedFiles] = useState<GridSelectionModel>([])
  const [linkExists, setLinkExists] = useState<boolean>(false)

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const location = useLocation()
  const { id } = useParams()

  function listObjects(){
    if (sampleId !== "" && incomingsBucket !== undefined){
      setLoading(true)
      S3.listObjects({
        Bucket: incomingsBucket,
        Prefix: `${sampleId}/`
      }).promise().then((response) => {
        if (response.Contents){
          setFiles(fcsFileHandler(response.Contents))
        }
      }).catch((err) => {
        setError(true)
      }).finally(() => {
        setLoading(false)
      })
    }
  }

  function deleteFiles(){
    if (incomingsBucket !== undefined){
      selectedFiles.forEach((file) => {
        S3.deleteObject({
          Bucket: incomingsBucket,
          Key: `${file}`
        }).promise().then(() => {
          setSelectedFiles([])
          listObjects()
        })
      })
    }
  }

  useEffect(() => {
    if(location.pathname.includes('/internal-control') && id !== undefined){
      httpClient.get(`internal_controls/${id}/link`).then((response) => setLinkExists(response.data))
    }
    listObjects()
  }, [])

  if (error === true){
    return <SnackBar open={true} severity='error' message={t('test.fcsFilesTab.messages.loadFailed')} />
  } else {
    return (
      <Box>
          <Box>
            <Typography variant="h6" mb={1}>{sampleId}</Typography>
            {incomingsBucket && <DropZone sampleId={sampleId} checkFileName={checkFileName} callbackFunction={() => listObjects()} bucketName={incomingsBucket} acceptRule=".fcs" disabled={linkExists}></DropZone>}
          </Box>
        <StripedDataGrid
          rows = {files}
          rowCount = {files.length}
          pageSize = {10}
          columns = {columns}
          getRowId={(row) => row.Key}
          rowsPerPageOptions={[10]}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          autoHeight
          checkboxSelection={true}
          onSelectionModelChange={(keys) => {
            setSelectedFiles(keys)
          }}
          sx={{marginTop: 2}}
          loading={loading}
        />
        <Stack direction="row" justifyContent="space-between">
          { linkExists === false ? (
            <IconButton onClick={() => deleteFiles()}><DeleteIcon /></IconButton> ) : (
            <Tooltip title={t('test.fcsFilesTab.messages.deleteDisabled')}><span><IconButton disabled><DeleteIcon /></IconButton></span></Tooltip>
            )}
          <IconButton onClick={() => listObjects()}><AutorenewIcon /></IconButton>
        </Stack>
      </Box>
    )
  }
}