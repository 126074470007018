import { GridColDef } from "@mui/x-data-grid";
import { S3 } from "../constants";
import i18n from '../i18n'

export let columns: GridColDef[] = setColumns()
let errorMessages = setErrorMessages()

i18n.on('loaded languageChanged', () => {
  columns = setColumns()
  errorMessages = setErrorMessages()
})

function setColumns(){
    return [
      { field: 'fileName', headerName: i18n.t('test.fcsFilesTab.columns.fileName'), flex: 1.5, minWidth: 300},
      { field: 'size', headerName: i18n.t('test.fcsFilesTab.columns.size'), flex: 1, minWidth: 50},
      { field: 'modifiedLast', headerName: i18n.t('test.fcsFilesTab.columns.lastModified'), flex: 2, minWidth: 200},
      { field: 'stress', headerName: i18n.t('test.fcsFilesTab.columns.stress'), flex: 1, minWidth: 50},
      { field: 'well', headerName: i18n.t('test.fcsFilesTab.columns.well'), flex: 1, minWidth: 50}
  ];
}

export function fcsFileHandler(data: Object[]){
    let files: Object[] = []
    const key = 'Key' as string
    const lastModified = 'LastModified' as string
    const sizeKey = 'Size' as string
    if (data.length){
      data.forEach((row) => {
        const fileName = row[key as keyof typeof row].toString().split('/')[1]
        const modifiedLast = row[lastModified as keyof typeof row].toString().split('(')[0]
        const size = (Number(row[sizeKey as keyof typeof row].toString())/(1024*1024)).toFixed(2)
        const [well, stress] = checkFileName(fileName)

        if (well !== false){
          Object.assign(row, {fileName, modifiedLast, size, stress, well})
          files.push(row)
        }
      })
    }
    return files
}

function setErrorMessages(){
  return [
    i18n.t('test.fcsFilesTab.messages.fileTypeError'),
    i18n.t('test.fcsFilesTab.messages.stressError'),
    i18n.t('test.fcsFilesTab.messages.wellError')
  ]
}

export function checkFileName(fileName: string){
  let stress = ""
  let well: number | undefined | string

  if (fileName.endsWith('.fcs') === false){ return [false, errorMessages[0]]}
  
  if (fileName.includes('8Gy')){
    stress = "8Gy"
  } else if (fileName.includes('Cp')){
    stress = "Cp"
  } else if (fileName.includes('CTR')){
    stress = "CTR"
  } else { return [false, errorMessages[1]]}

  well = fileName.split('-').pop()?.split('.')[0]
  
  if (well === undefined) { return [false, errorMessages[2]]}

  return [well, stress]
}

export const getNumberOfFiles = async (sampleId: string, incomingsBucket: string) => {
  return await new Promise((resolve, reject) => {
    S3.listObjects({
      Bucket: incomingsBucket,
      Prefix: `${sampleId}`
    }, (err, data) => {
      if (err){
        reject(err)
      }
      if (data){
        let length: number = 0
        data.Contents?.forEach((obj) => {
          if (obj.Key?.endsWith('.fcs')) length += 1
        })
        resolve(length)
      }
    })
  })
}
