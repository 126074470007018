import UserPool from "../../UserPool"
import { CognitoUser, AuthenticationDetails, CognitoUserSession } from "amazon-cognito-identity-js"

export const authenticate = async (Username: string, Password: string) => {
    return await new Promise((resolve, reject) => {
        const user = new CognitoUser({ Username, Pool: UserPool })
        const authDetails = new AuthenticationDetails({ Username, Password })

        user.authenticateUser(authDetails, {
            onSuccess: (data: CognitoUserSession) => resolve(data),
            onFailure: (err: Error) => reject(err)
        })
    })
}

export const getSession = async () => {
    return await new Promise((resolve, reject) => {
        const user = UserPool.getCurrentUser()
        if (user) {
            user.getSession((err: Error, session: CognitoUserSession | null) => {
                err ? reject() : resolve(session)
            })
        } else {
            reject()
        }
    })
}

export const logout = async () => {
    const user = UserPool.getCurrentUser()
    if (user) {
        user.signOut()
    }
}

export const getIdToken = async () => {
    return await new Promise((resolve, reject) => {
        const user = UserPool.getCurrentUser()
        if (user) {
            user.getSession((err: Error, session: CognitoUserSession | null) => {
                err ? reject() : resolve(session?.getIdToken().getJwtToken())
            })
        } else {
            reject()
        }
    })
}