import { Box, Stack, Typography, Button, AlertColor} from "@mui/material"
import { GridColDef } from "@mui/x-data-grid";
import { httpClient } from "..";
import BasicModal from "../components/BasicModal"
import i18n from '../i18n'

export let columns: GridColDef[] = []

i18n.on('loaded languageChanged', () => {
    columns = setColumns()
})

function setColumns(){
    return [
        { field: 'sample_id', headerName: i18n.t('dashboard.columns.sampleId', 'Sample ID'), flex: 1, minWidth: 200},
        { field: 'test', headerName: i18n.t('dashboard.columns.testType', 'Test Type'), flex: 1, minWidth: 200},
        { field: 'test_serial', headerName: i18n.t('dashboard.columns.testId', 'Test ID'), flex: 1, minWidth: 150},
        { field: 'sample_datetime', headerName: i18n.t('dashboard.columns.date', 'Date'), flex: 1, minWidth: 150},
        { field: 'processing_status', headerName: i18n.t('dashboard.columns.status', 'Status'), sortable: false, flex: 2, minWidth: 250}
    ];      
}

type Props = {
    open: boolean,
    setOpen: any,
    message: string,
    onClickLeft: any,
    onClickRight: any,
    leftButton?: string,
    rightButton?: string
}

export function CustomModal({open, setOpen, message, onClickLeft, onClickRight, leftButton, rightButton}: Props) {

    return (
        <BasicModal 
        isOpen={open}
        setOpen={setOpen}
        children={
        <Box>
            <Typography variant='h6' mb={2}>{message}</Typography>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button onClick={onClickLeft} variant='outlined'>{leftButton ? leftButton : 'Yes'}</Button>
            <Button onClick={onClickRight} variant='contained'>{rightButton ? rightButton : 'No'}</Button>
            </Stack>
        </Box>
        }
    />
    );
  }

export async function checkLastModified(lastModified: string, id: string | undefined){
    if (id !== undefined){
        return new Promise((resolve, reject) => {
            httpClient.get(`tests/${id}/`).then((res) => {
              if (lastModified !== res.data['blood_sample']['modified']){
                resolve(true)
              } else { resolve(false) }
            }).catch((err) => reject(err))
        })
    }
}

export const snackBarDefaultValues = {
    open: false,
    severity: "error" as AlertColor,
    message: ""
}