import { FormControl, FormControlLabel, Divider, Stack, FormLabel, Checkbox, Radio, RadioGroup, Grid, TextField, Box, FormHelperText } from '@mui/material';
import {BasicDatePicker} from '../DatePick';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
    clinicalParameters: {},
    register: any,
    control: any
}

export default function Patient({clinicalParameters, register, control}: Props){
    const { t, i18n } = useTranslation();

    return (
        <Box sx={{
            '& .MuiOutlinedInput-root': { width: {xs: '20ch', sm: '25ch', md: '30ch', xl: '40ch' }}
        }}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Stack spacing={2} direction="column" justifyContent='space-evenly' alignItems="flex-start">
                        <FormLabel required>{t('test.patientTab.birthDate')}</FormLabel>
                        <Controller control={control} name="birth_date" rules={{ required: t('test.patientTab.validation.birthDate')}} render={({ field, fieldState: {error}}) => (
                            <BasicDatePicker field={field} isError={error}/>
                        )}/>
                        <FormLabel required>{t('test.patientTab.gender')}</FormLabel>
                        <Controller control={control} name="gender" rules={{ required: t('test.patientTab.validation.gender') }} render={({ field, fieldState: {error} }) => (
                            <FormControl error={error? true : false}>
                            <RadioGroup row value={field.value}>
                                <FormControlLabel name='gender' control={<Radio size='small' value="F"/>} label={t('test.patientTab.female')} onChange={(value) => field.onChange(value)}/>
                                <FormControlLabel name='gender' control={<Radio size='small' value="M"/>} label={t('test.patientTab.male')} onChange={(value) => field.onChange(value)}/>
                            </RadioGroup>
                        <FormHelperText>{error?.message}</FormHelperText>
                        </FormControl>
                        )} />
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack spacing={2} direction="column" justifyContent='space-evenly' alignItems="flex-start">
                        <FormLabel>{t('test.patientTab.patientGuid')}</FormLabel>
                        <TextField size='small' {...register("patient_guid")}/>
                        <FormLabel>{t('test.patientTab.extReference')}</FormLabel>
                        <TextField size='small' {...register("ext_reference")}/>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Divider textAlign="left" sx={{ marginTop: 1, marginBottom: 1}}>{t('test.clinical.clinical')}</Divider>
                </Grid>
                <Grid container sx={{height: 150}}>
                    <Grid item xs={6}>
                        <Stack direction='column' justifyContent='space-evenly' alignItems="flex-start">
                            {Object.keys(clinicalParameters).map((param) => {
                                let options: string[]
                                options = clinicalParameters[param as keyof typeof clinicalParameters]
                                if (options.length === 2){
                                    return <FormControl key={param}>
                                    <Stack direction="row" alignItems="center" >
                                        <Controller control={control} name={param.replace(" ", "")} render={({ field }) => (
                                            <FormControlLabel value={true} control={<Checkbox size='small' checked={field.value === 0 ? false : true} onChange={(value) => field.onChange(value.target.checked === false ? 0 : 1)}/>} label={param}/>
                                        )}/>
                                    </Stack>
                                </FormControl>
                                }
                            })}
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        {Object.keys(clinicalParameters).map((param) => {
                            let options: string[]
                            options = clinicalParameters[param as keyof typeof clinicalParameters]
                            if (options.length === 3){
                                return <FormControl key={param} sx={{marginLeft: 2}}>
                                    <FormLabel>{param}</FormLabel>
                                    <Controller control={control} name={param.replace(" ", "")} render={({ field }) => (
                                        <RadioGroup value={field.value}>
                                            <FormControlLabel value={0} control={<Radio size='small'/>} label={t('test.clinical.options.no')} onChange={(value) => field.onChange(value)}/>
                                            <FormControlLabel value={1} control={<Radio size='small'/>} label={t('test.clinical.options.ongoing')}  onChange={(value) => field.onChange(value)}/>
                                            <FormControlLabel value={2} control={<Radio size='small'/>} label={t('test.clinical.options.planned')}  onChange={(value) => field.onChange(value)}/>
                                        </RadioGroup>
                                    )}/>
                                    
                                </FormControl>
                            }
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}