// @mui
import { Breakpoint, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// ----------------------------------------------------------------------

export default function useResponsive(query: string, key?: Breakpoint, start?: Breakpoint, end?: Breakpoint) {
  const theme = useTheme();

  if (query === 'up' && typeof key !== 'undefined') {
    return useMediaQuery(theme.breakpoints.up(key));
  }

  if (query === 'down' && typeof key !== 'undefined') {
    return useMediaQuery(theme.breakpoints.down(key));
  }

  if (query === 'between' && typeof start !== 'undefined' && typeof end !== 'undefined') {
    return useMediaQuery(theme.breakpoints.between(start, end));
  }

  if (query === 'only' && typeof key !== 'undefined') {
    return useMediaQuery(theme.breakpoints.only(key));
  }
  return null;
}
