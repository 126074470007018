import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { httpClient } from "..";
import { useCallbackPrompt } from "../hooks/useCallbackPrompt";
import {Container, Typography, Breadcrumbs, Link, Box, Tabs, Tab, Button } from '@mui/material';
import { FormProvider } from './hook-form';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Page from './Page';
import { CustomModal, checkLastModified, snackBarDefaultValues } from "../helpers/tests.helper"
import TabPanel from './TabPanel';
import Patient from './test_page_components/patientTab';
import Sample from './test_page_components/sampleTab';
import Results from './test_page_components/resultsTab'
import FCSFiles from './test_page_components/FCSFiles';
import { Reagents } from './test_page_components/reagentsTab';
import SnackBar from "./Snackbar";

type Props = {
    buckets: {
      incomings: string,
      digested: string
    } | undefined,
    pageTitle: string,
    testType: string,
    clinicalParameters: any,
    defaultValues: any,
    handlePreviousData: Function,
    updateData: Function
}

export default function Test({buckets, pageTitle, testType, clinicalParameters, defaultValues, handlePreviousData, updateData}: Props) {
    let location = useLocation()
    const { t, i18n } = useTranslation();
  
    const navigate = useNavigate()
    const [tab, setTab] = useState<number>(0)
    const [openSnackbar, setSnackbar] = useState(snackBarDefaultValues)
    const [openCancelModal, setCancelModal] = useState(false)
    const [requiredError, setRequiredError] = useState(false)
    const [lastModified, setLastModified] = useState<string>("")
    const [openReloadModal, setReloadModal] = useState<boolean>(false)
    const [showDialog, setShowDialog] = useState<boolean>(false)
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog)
    const { id } = useParams();
    const [oldFormValues, setOldFormValues] = useState(defaultValues)
  
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
      if (newValue === 4){
        checkLastModified(lastModified, id).then((isModified) => {
          if (isModified) { setReloadModal(true) }
        })
      }
      setTab(newValue)
    }
  
    const methods = useForm({
      mode: "onChange",
      reValidateMode: "onChange",
      criteriaMode: "firstError",
      shouldFocusError: true,
      defaultValues,
    });
  
    const { control, register, handleSubmit, formState: {dirtyFields, isDirty}, getValues, watch, reset, setError, setValue} = methods;

    useEffect(() => {
      reset(defaultValues)
      if (id !== undefined){
        httpClient.get(`tests/${id}/`).then((response) => {
          handlePreviousData(response)
          setLastModified(response.data['blood_sample']['modified'])
          reset(response.data)
          setOldFormValues(response.data)
        })
      }
      setTab(0)
    }, [location])
  
    const handleCancelButton = () => {
      reset(defaultValues)
      setCancelModal(false)
      setRequiredError(false)
    }
  
    useEffect(() => {
      isDirty === true ? setShowDialog(true) : setShowDialog(false)
    }, [isDirty])
  
    useEffect(() => {
      if (lastModified !== ""){
        let interval = setInterval(() => {
          checkLastModified(lastModified, id).then((isModified) => {
            if(isModified){
              setReloadModal(true)
            }
          })
        }, 1000*60)
        return () => clearInterval(interval);
      }
    }, [lastModified])
  
    function checkRequiredFields(){
      if (!dirtyFields.blood_sample?.sample_id){
        setError('blood_sample.sample_id', { type: "focus", message: 'Sample ID is required'}, { shouldFocus: true })
        setRequiredError(true)
      }
      if (!dirtyFields.blood_sample?.lab_reception_user){
        setError('blood_sample.lab_reception_user', { type: "focus", message: 'Lab user is required'}, { shouldFocus: true })
        setRequiredError(true)
      }
      if (!dirtyFields.blood_sample?.sample_datetime){
        setError('blood_sample.sample_datetime', { type: "focus", message: 'Sample datetime is required'}, { shouldFocus: true })
        setRequiredError(true)
      }
    }
  
    function compareForm(data: any){
      let toPatch = {} as any
      for (let [key, value] of Object.entries(oldFormValues)){
        if (key === "blood_sample"){
          for (let [bsKey, bsValue] of Object.entries(oldFormValues.blood_sample)){
            if(bsValue !== data[key][bsKey]){
              toPatch[bsKey] = data[key][bsKey]
            }
          }
        } else {
          if (value !== data[key]){
            toPatch[`${key}`] = data[key]
          }
        }
      }
      return toPatch
    }
  
    const onSubmit = (data: any) => {
      setShowDialog(false)
      setSnackbar(snackBarDefaultValues)
      setRequiredError(false)
      data = updateData(data)
      if (id === undefined){
        checkRequiredFields()
        if (requiredError === false){
          httpClient.post('/tests/', data).then((res) => {
            if (res.status !== 201){
              if (res?.data?.blood_sample?.non_field_errors){
                setTab(1)
                setError("blood_sample.sample_id", { type: "focus", message: t('test.messages.sampleExists')}, { shouldFocus: true })
              }
              setSnackbar({ open: true, severity: "error", message: t('test.messages.saveFailed')})
            } else {
              setSnackbar({ open: true, severity: "success", message: t('test.messages.saveSuccess')})
              navigate(`${location.pathname}/${res.data}`)
            }
          })
        }  
      } else {
        checkLastModified(lastModified, id).then((isModified) => {
          if (!isModified){
            httpClient.patch(`tests/${id}/`, compareForm(data)).then((response) => {
              if (response.status === 200){
                httpClient.get(`tests/${id}/`).then((response) => setLastModified(response.data['blood_sample']['modified']))
                setSnackbar({ open: true, severity: "success", message: t('test.messages.updateSuccess')})
                setShowDialog(false)
              } else { setSnackbar({ open: true, severity: "error", message: t('test.messages.updateFailed')}) }
            })
          } else {
            setReloadModal(true)
          }
        })
      }
    };
  
    return (
      <Page title={pageTitle}>
        <Container maxWidth="xl">
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
            <Link underline="hover" color="inherit" href="/dashboard/app">{t(`${testType}.breadcrumb.dashboard`, 'Dashboard')}</Link>
            <Typography>{t(`${testType}.breadcrumb.newTest`, 'New Test')}</Typography>
            <Typography>{t(`${testType}.breadcrumb.rila`, 'RILA')}</Typography>
            <Typography color="text.primary">{t(`${testType}.breadcrumb.test`)}</Typography>
          </Breadcrumbs>
          <Typography variant="h4">{t(`${testType}.title`)}</Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Tabs value={tab} onChange={handleTabChange} centered sx={{
                '& .MuiTab-root.Mui-selected': { backgroundColor: 'white', borderRadius: 1}, '& .MuiTab-root': {marginRight: 1, marginLeft: 1}}}>
              <Tab label={t('test.tabs.patient', 'Patient')}/>
              <Tab label={t('test.tabs.sample', 'Sample')} sx={{color: requiredError ? "red" : "none"}}/>
              <Tab label={t('test.tabs.reagents', 'Reagents')} />
              <Tab label={t('test.tabs.fcsFiles', 'FCS Files')} disabled={watch('blood_sample.sample_id') === "" ? true : false}/>
              <Tab label={t('test.tabs.results', 'Results')} disabled={id === undefined ? true : false}/>
            </Tabs>
            <Box sx={{backgroundColor: 'white', borderRadius: 1, paddingBottom: 1}}>
              <TabPanel value={tab} index={0}>
                <Patient clinicalParameters={clinicalParameters} register={register} control={control}/>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <Sample register={register} control={control} id={id} getValues={getValues}/>
              </TabPanel>
              <TabPanel value={tab} index={2}>
                <Reagents getValues={getValues} setValue={setValue}/>
              </TabPanel>
              <TabPanel value={tab} index={3}>
                <FCSFiles sampleId={getValues('blood_sample.sample_id')} incomingsBucket={buckets?.incomings}/>
              </TabPanel>
              <TabPanel value={tab} index={4}>
                <Results id={id} buckets={buckets} sampleId={getValues('blood_sample.sample_id')} setLastModified={setLastModified}/>
              </TabPanel>
              <Box display="flex" justifyContent="flex-end" mr={3}>
                {id === undefined && <Button variant="outlined" sx={{marginRight: 2}} onClick={() => setCancelModal(true)}>{t('common.buttons.cancelBtn', 'Cancel')}</Button>}
                {id !== undefined && <Button variant="outlined" sx={{marginRight: 2}} onClick={() => navigate('/dashboard/app')}>{t('common.buttons.cancelBtn', 'Cancel')}</Button>}
                <Button type="submit" variant='contained'>{t('common.buttons.saveBtn', 'Save')}</Button>
              </Box>
            </Box>
          </FormProvider>
          <CustomModal 
            open={showPrompt as boolean} 
            setOpen={setCancelModal} 
            message={t('test.messages.quitForm')}
            onClickRight={cancelNavigation as () => void} 
            onClickLeft={confirmNavigation as () => void} />
          <CustomModal 
            open={openCancelModal} 
            setOpen={setCancelModal} 
            message={t('test.messages.clearForm')}
            onClickRight={() => setCancelModal(false)} 
            onClickLeft={handleCancelButton} />
          <CustomModal 
            open={openReloadModal} 
            setOpen={setReloadModal} 
            message={t('test.messages.sampleModified')}
            onClickRight={() => window.location.reload()} 
            onClickLeft={() => setReloadModal(false)} 
            rightButton={t('common.buttons.reloadBtn', 'Reload')}
            leftButton={t('common.buttons.cancelBtn', 'Cancel')}/>
          {openSnackbar.open && <SnackBar open={openSnackbar.open} severity={openSnackbar.severity} message={openSnackbar.message}/>}
        </Container>
      </Page>
    );
  }