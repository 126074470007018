import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

type Props = {
  field: any,
  isError?: any
}

export function BasicDatePicker({field, isError}: Props) {

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        onChange={(date) => field.onChange(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`)}
        value={field.value}
        inputFormat="yyyy-MM-dd"
        mask="____-__-__"
        renderInput={(params) => <TextField {...params} 
          size="small"
          error={!!isError}
          helperText={isError?.message}/>}
      />
    </LocalizationProvider>
  );
}

export function BasicDateTimePicker({field, isError}: Props){
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker  
        onChange={(date) => field.onChange(date)}
        value={field.value}
        renderInput={(params) => <TextField {...params} 
        size="small"
        error={!!isError} 
        helperText={isError?.message}/>}
      />
    </LocalizationProvider>
  )
}

