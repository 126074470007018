import { Theme} from "@mui/material";
import { customShadows } from "../shadows";
// ----------------------------------------------------------------------

export default function Autocomplete(theme: Theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow: customShadows.z20,
        },
      },
    },
  };
}
