import { handlePreviousData, defaultValues, clinicalParameters, updateData} from '../components/tests/multiParamProstateTest'
import Test from '../components/Test'

type Props = {
  buckets: {
    incomings: string,
    digested: string
  } | undefined,
  pk: number | undefined
}

export default function MultiParametricProstateTest({buckets, pk}: Props) {
  return <Test 
    buckets={buckets}
    pageTitle="Multi-parametric Prostate Test"
    testType="multiParamProstateTest"
    clinicalParameters={clinicalParameters}
    defaultValues={defaultValues(pk === undefined? 1 : pk)}
    handlePreviousData={handlePreviousData}
    updateData={updateData} />
}