import { handlePreviousData, defaultValues, clinicalParameters, updateData} from '../components/tests/rilaProstateTest'
import Test from '../components/Test'

type Props = {
  buckets: {
    incomings: string,
    digested: string
  } | undefined,
  pk: number | undefined
}

export default function RILAProstateTest({buckets, pk}: Props) {
  return <Test 
    buckets={buckets}
    pageTitle="Rila Prostate Test"
    testType="rilaProstateTest"
    clinicalParameters={clinicalParameters}
    defaultValues={defaultValues(pk === undefined? 1 : pk)}
    handlePreviousData={handlePreviousData}
    updateData={updateData} />
}