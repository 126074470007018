import { Snackbar, Alert, AlertColor } from '@mui/material';
import Slide, {SlideProps} from '@mui/material/Slide';
import { useState } from 'react';

// ----------------------------------------------------------------------

type TransitionProps = Omit<SlideProps, 'direction'>;

function TransitionLeft(props: TransitionProps) {
  return <Slide {...props} direction="left" />;
}

type Props = {
    open: boolean,
    severity: AlertColor | undefined,
    message: string,
}

export default function SnackBar({open, severity, message}: Props) {
    const [isOpen, setOpen] = useState(open)

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          setOpen(false);
          return;
        }
    }

    return(
        <Snackbar 
        open={isOpen}
        autoHideDuration={30}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right' }}
        TransitionComponent={TransitionLeft}
        onClose={handleClose}>
            <Alert severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </ Snackbar>
    )
}
