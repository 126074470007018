import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authenticate } from '../Authentication';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
  } = methods;

  const onSubmit = async (data: {email: string, password: string}) => {
    setIsSubmitting(true)
    authenticate(data.email, data.password).then(() => {
      navigate('/dashboard/app', { replace: true , state: { isLoginSuccessfull: true}});
    }).catch(() => {
      setError("email", { type: "focus", message: 'Login data incorrect'}, { shouldFocus: true })
      setError("password", { type: "focus", message: 'Login data incorrect'}, { shouldFocus: true })
      setIsSubmitting(false)
    })
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="email" label={t('login.form.email')} />

        <RHFTextField
          name="password"
          label={t('login.form.password')}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link variant="subtitle2" underline="hover" href="mailto:support@nova-gray.com?subject=I've lost my password">
          {t('login.form.forgotPassword')}
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        {t('login.form.loginBtn')}
      </LoadingButton>
    </FormProvider>
  );
}
