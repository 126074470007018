// @mui
import { Container, Typography, Breadcrumbs, TextField, Stack, IconButton} from '@mui/material';
import { useLocation } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
// components
import Page from '../components/Page';
import Snackbar from '../components/Snackbar';
import GridTests from '../components/GridTests'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

type Props = {
  customerName: string | undefined
}

export default function DashboardApp({customerName}: Props) {
  const { t, i18n } = useTranslation();

  const location = useLocation()
  const [searchInput, setSearchInput] = useState("")

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}><Typography color="text.primary">{t('dashboard.breadcrumb.dashboard', 'Dashboard')}</Typography></Breadcrumbs>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-end" sx={{marginBottom: 1}}>
          <Typography variant="h4">{t('dashboard.title', `Tests of ${customerName}` ,{laboratory: customerName})}</Typography>
          <TextField 
            variant="outlined"
            placeholder={t('dashboard.search', 'Search')}
            size='small'
            InputProps={{ 
              startAdornment: (<SearchIcon sx={{marginRight: 1}}/>),
              endAdornment: (<IconButton onClick={() => setSearchInput("")}><ClearIcon fontSize='small'/></IconButton>),
              style: {paddingRight: 0}}}
            value={searchInput}
            onChange={(event) => {setSearchInput(event.target.value)}}
            />
        </Stack>
        <GridTests searchValue={searchInput? `&search=${searchInput}` : ""}/>
      </Container>
      <Snackbar open={location.state ? true : false} severity={"success"} message={t('dashboard.loginSuccessful', 'Login Successful')}/>
    </Page>
  );
}
