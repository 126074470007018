import {Container, Typography, Breadcrumbs, Link, Box, Button, Checkbox} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// components
import Page from '../components/Page';
import { StripedDataGrid } from  '../components/StripedDataGrid'
import { useEffect, useState } from 'react';
import { httpClient } from '../index'
import { useNavigate } from 'react-router-dom';
import { InternalControl } from "../helpers/internalControl.helper"
import { useTranslation } from 'react-i18next';
import { GridColDef } from '@mui/x-data-grid';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
// ----------------------------------------------------------------------

type Props = {
  customerName: string | undefined
}

export default function InternalControls({customerName}: Props) {
  const [internalControls, setInternalControls] = useState<InternalControl[]>([])
  const navigate = useNavigate()
  const [offset, setOffset] = useState(0)
  const [nbTests, setNbTests] = useState(0)
  const { t, i18n } = useTranslation();

  const columns: GridColDef[] = [
    { field: 'serial_number', headerName: t('internalControls.columns.icSerial', 'IC Serial'), flex: 2, minWidth: 150},
    { field: 'date', headerName: t('internalControls.columns.date', 'Date'), flex: 2, minWidth: 150},
    { field: 'batch_id', headerName: t('internalControls.columns.icBatch', 'IC Batch'), flex: 2, minWidth: 150},
    { field: 'is_valid', headerName: t('internalControls.columns.valid', 'Valid'), sortable: false, flex: 1, minWidth: 50, renderCell: (param) => {
      if (param.value === "Validated"){
        return <Checkbox  checked={true} disabled={true}/>
      } else if(param.value === "Not Validated"){ return <Checkbox icon={<DisabledByDefaultRoundedIcon />}/>
      } else return ""
    }}
  ];

  function getIcTests(){
    httpClient.get(`/internal_controls/?limit=10&offset=${offset}`).then((response) => {
      response.data.results? setInternalControls(response.data.results) : setInternalControls([])
      setNbTests(response.data.count)
    })
  }

  useEffect(() => {
    getIcTests()
  }, [offset])

  return (
    <Page title="Internal Control">
      <Container maxWidth="xl">
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
          <Link underline="hover" color="inherit" href="/dashboard/app">{t('internalControls.breadcrumb.dashboard', 'Dashboard')}</Link>
          <Typography color="text.primary">{t('internalControls.breadcrumb.internalControl', 'Internal Control')}</Typography>
        </Breadcrumbs>
        <Box display="flex" justifyContent="space-between" mb={1} alignItems="baseline">
          <Typography mb={2} variant="h4">{t('internalControls.title', `Tests of ${customerName}`, { laboratory: customerName })}</Typography>
            <Box>
              <Button variant="outlined" onClick={() => navigate('/dashboard/internal-control/new')}>{t('internalControls.newICBtn', 'New Internal Control')}</Button>
            </Box>
        </Box>
        <StripedDataGrid  
          rows={internalControls}
          columns={columns}
          rowCount={nbTests}
          autoHeight
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row.test_serial}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          onCellClick={(row) => {
            navigate(`/dashboard/internal-control/${row.id}`)
          }}
          paginationMode="server"
          onPageChange={(newPage) => setOffset(10 * newPage)}
          sx={{'& .MuiDataGrid-row:hover': {
            cursor: "pointer"
          }}}
        />
      </Container>
    </Page>
  );
}