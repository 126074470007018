import React, { useEffect, Suspense } from 'react'
// routes
import { useNavigate } from 'react-router-dom'
import Router from './routes'
// theme
import ThemeProvider from './theme/index'
// components
import ScrollToTop from './components/ScrollToTop'
import { getSession, logout } from './sections/auth/Authentication'

// ----------------------------------------------------------------------

export default function App () {
  const navigate = useNavigate()

  window.addEventListener('storage', () => {
    logoutAndRedirect()
  }, false)

  function logoutAndRedirect(){
    logout().then(() => {
      navigate('/login')
    })
  }

  useEffect(() => {
    getSession().then(() => {
      setTimeout(() => logoutAndRedirect(), 12 * 60 * 60 * 100) // logout user after 12 hours
    }).catch(() => {
      navigate('/login')
    })
  }, [])

  return (
      <ThemeProvider>
        <ScrollToTop />
        <Suspense fallback="loading">
          <Router />
        </Suspense>
      </ThemeProvider>
  )
}
