import React, { useState, useEffect } from 'react'
import { GridColDef, useGridApiContext, useGridSelector, gridPageSelector, gridPageCountSelector, gridClasses, GridSortModel} from '@mui/x-data-grid';
import { Pagination } from '@mui/material'
import SnackBar from './Snackbar';
import { httpClient } from '../index'
import { StripedDataGrid } from './StripedDataGrid';
import { useNavigate } from 'react-router-dom';
import { columns } from '../helpers/tests.helper'

export default function GridTests(prop: {searchValue :string}) {
  const navigate = useNavigate();

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSortModel] = useState("")

  const [loading, setLoading] = useState(false);

  const [nbTests, setnbTests] = useState(0)
  const [tableData, setTableData] = useState([])
  const [error, setError] = useState(false)

  const handleSortModelChange = (sortModel: GridSortModel) => {
    let sortField = ""
    if (sortModel[0]){
      if (sortModel[0].field === "test"){
        sortField = "test_content_type"
      }else if (sortModel[0].field === "test_serial"){
        sortField = "test_id"
      }else {
        sortField = sortModel[0].field
      }
      if (sortModel[0].sort === "asc"){
        setSortModel(`&ordering=${sortField}`)
      } else if (sortModel[0].sort === "desc"){
        setSortModel(`&ordering=-${sortField}`)
      }
    } else {
      setSortModel("")
    }
  }

  function getTests(){
    httpClient.get(`tests/?limit=${limit}&offset=${offset}${sort}${prop.searchValue}`).then((response) => {
      setTableData(response.data.results)
      setnbTests(response.data.count)
      setLoading(false)
    }).catch(() => {
      setError(true)
      setLoading(false)
    })
  }

  const handleCellClick = (row: any) => {
    if (row.row.test === "NovaGray RILA Breast"){
      navigate(`/dashboard/rila-breast-test/${row.id}`, { replace: true , state: { testID: row.id}})
    } else if (row.row.test === "NovaGray RILA Prostate"){
      navigate(`/dashboard/rila-prostate-test/${row.id}`, { replace: true , state: { testID: row.id}})
    } else if (row.row.test === "NovaGray Prostate Test"){
      navigate(`/dashboard/multi-param-prostate-test/${row.id}`, { replace: true , state: { testID: row.id}})
    } else if (row.row.test === "NovaGray Breast Test"){
      navigate(`/dashboard/multi-param-breast-test/${row.id}`, { replace: true , state: { testID: row.id}})
    }
  }

  useEffect(() => {
    setLoading(true)
    getTests()
    const interval = setInterval(() => {
      getTests()
    }, 1000 * 10);
    return () => clearInterval(interval);
  }, [limit, offset, sort, prop.searchValue])

  if (error === true){
    return <SnackBar open={true} severity='error' message='Failed to download tests' />
  }else{
    return (
      <div style={{marginTop: 2}}>
        <StripedDataGrid 
          rows={tableData}
          columns={columns}
          getRowId={(row) => row.test_serial}
          rowCount={nbTests}
          pageSize={limit}
          rowsPerPageOptions={[10]}
          sortingMode="server"
          paginationMode="server"
          onPageChange={(newPage) => setOffset(limit * newPage)}
          onSortModelChange={(newSortModel) => handleSortModelChange(newSortModel)}
          components={{Pagination: CustomPagination}}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          loading={loading}
          autoHeight
          disableColumnFilter
          onCellClick={(row) => {handleCellClick(row)}}
          sx={{'& .MuiDataGrid-row:hover': {
            cursor: "pointer"
          }}}
        />
      </div>
    );
  }
  
}

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}