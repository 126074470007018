import { Typography , Checkbox, Box, Button, AlertColor, useTheme, FormLabel, Stack, List, ListItem, ListItemText } from "@mui/material";
import TimelineDot from '@mui/lab/TimelineDot';
import { GridColDef } from "@mui/x-data-grid";
import { Dispatch, useEffect, useState } from "react";
import { httpClient } from "../../index";
import { StripedDataGrid } from "../StripedDataGrid";
import SnackBar from '../Snackbar'
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import { useLocation } from "react-router-dom";
import { Result, computationStatus } from "../../helpers/result.helper"
import { handleDownload} from "../../helpers/download.helper"
import { getNumberOfFiles } from "../../helpers/fcsFiles.helper"
import BasicModal from "../BasicModal";
import { useTranslation } from 'react-i18next';

type Props = {
    id: string | undefined,
    buckets: {
        incomings: string,
        digested: string
    } | undefined,
    sampleId: string,
    setLastModified: Dispatch<string>
}

export default function Results({id, buckets, sampleId, setLastModified}: Props){

    const snackBarDefaultValues = {
        open: false,
        severity: "error" as AlertColor,
        message: ""
    }

    const location = useLocation()
    const [openSnackbar, setSnackbar] = useState(snackBarDefaultValues)
    const [open, setOpen] = useState<boolean>(false)
    const { t, i18n } = useTranslation();

    const columns: GridColDef[] = [
        { field: 'name', headerName: t('test.resultsTab.columns.documentName'), flex: 2, minWidth: 150, renderCell: (param) => (
            <a href="#" onClick={(e) => handleDownload(e, param.row.path, buckets?.digested as string, setSnackbar)}>{param.value}</a>
        )},
        { field: 'language', headerName: t('test.resultsTab.columns.language'), flex: 1, minWidth: 50},
        { field: 'datetime', headerName: t('test.resultsTab.columns.datetime'), flex: 2, minWidth: 100},
        { field: 'format', headerName: t('test.resultsTab.columns.format'), flex: 1, minWidth: 50},
    ];

    const theme = useTheme();
    const [results, setResults] = useState<Result>()
    const [reports, setReports] = useState<Object[]>([])
    const [isComputing, setIsComputing] = useState(false)
    const [nbOfFiles, setNbOfFiles] = useState<number>(0)
    const canRecompute: boolean = nbOfFiles > 5 ? true : false

    function handleCompute(){
        if(nbOfFiles < 8) {
            setOpen(true)
        } else { triggerCompute() }
    }

    function triggerCompute(){
        httpClient.patch(`tests/${id}/compute`).then((res) => {
            res.status === 200 ? 
                setSnackbar({ open: true, severity: "success", message: t('test.resultsTab.messages.recomputing') }) :
                setSnackbar({open: true, severity: "error", message: t('test.resultsTab.messages.recomputeFailed') })
            setIsComputing(true)
            httpClient.get(`tests/${id}/`).then((response) => {
                setLastModified(response.data['blood_sample']['modified'])
            })
        })
       setOpen(false)
    }

    function getResults(){
        httpClient.get(`${location.pathname.includes('/internal-control') ? "internal_controls" : "tests"}/${id}/result`).then((response) => {
            setResults(response.data)
            if (response.data.processing_status === computationStatus.calcRunning || response.data.processing_status === computationStatus.fileSetComplete){
                setIsComputing(true)
            } else { setIsComputing(false) }
        })
    }

    useEffect(() => {
        const getFileNumber = async () => {
            if (sampleId !== "" && sampleId !== undefined && buckets?.incomings !== undefined) {
                const fileNumber = await getNumberOfFiles(sampleId, buckets.incomings)
                setNbOfFiles(Number(fileNumber))
            }
        }
        getFileNumber()
    }, [sampleId, buckets])

    useEffect(() => {
        getResults()
        httpClient.get(`tests/${id}/document`).then((response) => {
            setReports(response.data)
        })
    }, [openSnackbar, results?.processing_status])

    useEffect(() => {
        if (results && results?.processing_status !== computationStatus.readyToCompute){
            httpClient.get(`tests/${id}/`).then((response) => {
                setLastModified(response.data['blood_sample']['modified'])
            })
        }
    }, [results?.processing_status])

    useEffect(() => {
        let interval: string | number | NodeJS.Timeout | undefined
        if (isComputing === true){
            interval = setInterval(() => {
                getResults()
            }, 1000*3)
        }
        return () => clearInterval(interval);
    }, [isComputing])

    return (
        <Box >
            { openSnackbar.open && <SnackBar open={openSnackbar.open} severity={openSnackbar.severity} message={openSnackbar.message} />}
            <Box display="flex" justifyContent="flex-end" mt={1}><Button variant='outlined' onClick={() => handleCompute()} disabled={!canRecompute || results?.processing_status === computationStatus.calcRunning || results?.processing_status === computationStatus.fileSetComplete}>{t('common.buttons.recompute')}</Button></Box>
                { location.pathname.includes('/internal-control')? (
                    <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 6fr', columnGap: 5, rowGap: 1, marginBottom: 2}}>
                        <Typography>{t('test.resultsTab.serialNumber')}:</Typography>
                        <Typography> {results?.serial_number}</Typography>
                        <Typography>{t('test.resultsTab.status')}:</Typography>
                        <Typography>{results?.processing_status}</Typography>
                        <Typography>{t('test.resultsTab.status')}:</Typography>
                        <Stack direction="row" alignItems="flex-start">
                            <FormLabel sx={{color: theme.palette.grey[800]}}>{t('test.resultsTab.interpretable')}</FormLabel>
                            {results?.interpretable === true && <Checkbox icon={<CheckBoxRoundedIcon />} style={{color: theme.palette.success.main}} disabled sx={{paddingTop: 0, paddingBottom: 0 }} checked={results?.interpretable}/>}
                            {results?.interpretable === false && <Checkbox icon={<DisabledByDefaultRoundedIcon />} style={{color: theme.palette.error.main}} disabled sx={{paddingTop: 0, paddingBottom: 0 }} checked={results?.interpretable}/>} 
                        </Stack>
                        <Typography>{t('test.resultsTab.value')}:</Typography>
                        <Box>
                            <Typography display="inline" mr={2}>{results?.rila_score} % </Typography>
                            {results?.is_valid === "Validated" && <Typography sx={{color: theme.palette.success.main}} display="inline">{t('test.resultsTab.validCompare', {refValue: results?.reference_value})}</Typography>}
                            {results?.is_valid === "Not Validated" && <Typography sx={{color: theme.palette.error.main}} display="inline">{t('test.resultsTab.invalidCompare', {refValue: results?.reference_value})}</Typography>} </Box>
                    </Box>
                ):(
                    <Stack direction="row" justifyContent="flex-start" >
                        <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 6fr', columnGap: 5, rowGap: 1, marginBottom: 2}}>
                            <Typography>{t('test.resultsTab.serialNumber')}:</Typography>
                            <Typography> {id}</Typography>
                            <Typography>{t('test.resultsTab.status')}:</Typography>
                            <Typography>{results?.processing_status}</Typography>
                            <Typography>{t('test.resultsTab.rilaStatus')}:</Typography>
                            <Stack direction="row" alignItems="flex-start">
                                <FormLabel sx={{color: theme.palette.grey[800]}}>{t('test.resultsTab.interpretable')}</FormLabel>
                                {results?.interpretable === true && <Checkbox icon={<CheckBoxRoundedIcon />} style={{color: theme.palette.success.main}} disabled sx={{paddingTop: 0, paddingBottom: 0 }} checked={results?.interpretable}/>}
                                {results?.interpretable === false && <Checkbox icon={<DisabledByDefaultRoundedIcon />} style={{color: theme.palette.error.main}} disabled sx={{paddingTop: 0, paddingBottom: 0 }} checked={results?.interpretable}/>} 
                            </Stack>
                            <Typography>{t('test.resultsTab.rilaValue')}:</Typography>
                            <Typography>{results?.rila_score} %</Typography>
                        </Box>
                    <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 3fr', columnGap: 5, rowGap: 1, marginBottom: 2}}>
                        <Typography>{t('test.resultsTab.qualityControls')}:</Typography>
                        <List disablePadding>
                            <ListItem disablePadding sx={{marginTop: -0.5}}><TimelineDot sx={{margin: 1, backgroundColor: results?.quality_control?.Beckman === true ? theme.palette.success.light : theme.palette.error.main}}/><ListItemText primary="Beckman" sx={{marginLeft: 1}}/></ListItem>
                            <ListItem disablePadding><TimelineDot sx={{margin: 1, backgroundColor: results?.quality_control?.NovaGray === true ? theme.palette.success.light : theme.palette.error.main}}/><ListItemText primary="NovaGray" sx={{marginLeft: 1}}/></ListItem>
                            <ListItem disablePadding><TimelineDot sx={{margin: 1, backgroundColor: results?.quality_control?.IC === true ? theme.palette.success.light : theme.palette.error.main}}/><ListItemText primary="CI" sx={{marginLeft: 1}}/></ListItem>
                            <ListItem disablePadding><TimelineDot sx={{margin: 1, backgroundColor: results?.quality_control?.Timing === true ? theme.palette.success.light : theme.palette.error.main}}/><ListItemText primary="Timing" sx={{marginLeft: 1}}/></ListItem>
                        </List>
                    </Box>
                </Stack>
            )}
            <StripedDataGrid 
                columns={columns}
                rows={reports} 
                rowCount={reports.length}
                pageSize={10}
                getRowId={(row) => row.path}
                rowsPerPageOptions={[10]}
                autoHeight
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                disableSelectionOnClick
            />
            <BasicModal isOpen={open} setOpen={setOpen} children={
                <Box>
                    <Typography mb={2}>{t('test.resultsTab.messages.lessThen8Files')}</Typography>
                    <Stack direction="row" justifyContent="flex-end" spacing={2}>
                        <Button onClick={() => triggerCompute()} variant='outlined'>{t('common.buttons.yes')}</Button>
                        <Button onClick={() => setOpen(false)} variant='contained'>{t('common.buttons.no')}</Button>
                    </Stack>
                </Box>
            }/>
        </Box>
    )
}