import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import DashboardApp from './pages/DashboardApp';
import InternalControls from './pages/InternalControls';
import InternalControl from './pages/InternalControl';
import RILABreastTest from './pages/RILABreastTest';
import RILAProstateTest from './pages/RILAProstateTest';
import MultiParametricBreastTest from './pages/MultiParamBreastTest';
import MultiParametricProstateTest from './pages/MultiParamProstateTest';
import QualityControl from './pages/QualityControl';
import Contact from './pages/Contact';
import { useState } from 'react';
// ----------------------------------------------------------------------

interface CustomerDetails {
  name: string, 
  buckets: {
    incomings: string,
    digested: string
  },
  pk: number
}

interface UserDetails {
  pk: number,
  email: string, 
  first_name: string,
  last_name: string,
}

export default function Router() {

  const [customerDetails, setCustomerDetails] = useState<CustomerDetails>()
  const [userDetails, setUserDetails] = useState<UserDetails>()
  
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout setUserDetails={setUserDetails} setCustomerDetails={setCustomerDetails}/>,
      children: [
        { path: 'app', element: <DashboardApp customerName={customerDetails?.name}/> },
        { path: 'user', element: <User userDetails={userDetails} customerName={customerDetails?.name}/> },
        { path: 'internal-control', element: <InternalControls customerName={customerDetails?.name}/> },
        { path: 'internal-control/:id', element: <InternalControl buckets={customerDetails?.buckets} pk={customerDetails?.pk}/> },
        { path: 'internal-control/new', element: <InternalControl buckets={customerDetails?.buckets} pk={customerDetails?.pk}/>},
        { path: 'rila-breast-test', element: <RILABreastTest buckets={customerDetails?.buckets} pk={customerDetails?.pk}/> },
        { path: 'rila-breast-test/:id', element: <RILABreastTest buckets={customerDetails?.buckets} pk={customerDetails?.pk}/>},
        { path: 'rila-prostate-test', element: <RILAProstateTest buckets={customerDetails?.buckets} pk={customerDetails?.pk}/> },
        { path: 'rila-prostate-test/:id', element: <RILAProstateTest buckets={customerDetails?.buckets} pk={customerDetails?.pk}/> },
        { path: 'multi-param-breast-test', element: <MultiParametricBreastTest buckets={customerDetails?.buckets} pk={customerDetails?.pk}/>},
        { path: 'multi-param-breast-test/:id', element: <MultiParametricBreastTest buckets={customerDetails?.buckets} pk={customerDetails?.pk}/>},
        { path: 'multi-param-prostate-test', element: <MultiParametricProstateTest buckets={customerDetails?.buckets} pk={customerDetails?.pk}/>},
        { path: 'multi-param-prostate-test/:id', element: <MultiParametricProstateTest buckets={customerDetails?.buckets} pk={customerDetails?.pk}/>},
        { path: 'quality-control', element: <QualityControl customerName={customerDetails?.name} digestedBucket={customerDetails?.buckets.digested}/>},
        { path: 'contact', element: <Contact />}
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
