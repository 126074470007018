import { AxiosResponse } from "axios"
import i18n from '../../i18n'

export function handlePreviousData(response: AxiosResponse){
    response.data['Diabetes'] = response.data.diabetes
    response.data['Tobacco'] = response.data.tobacco
    response.data['Chemotherapy'] = response.data.chemo
    response.data['Hormonotherapy'] = response.data.hormono
}

export const defaultValues = (pk: number) => {
    return {
        test_type: "NGB",
        Hormonotherapy: null,
        Diabetes: 0,
        Tobacco: 0,
        Chemotherapy: 0,
        birth_date: null,
        gender: null,
        patient_guid: "",
        ext_reference: "",
        blood_sample: {
            sample_id: "",
            customer: pk,
            internal_control: "",
            sample_datetime: null,
            cell_culture_start_time: null,
            cell_culture_end_time: null,
            irradiation_timestamp: null,
            lab_reception_user: "",
            lab_reception_form: false,
            usable_sample: 0,
            notes: "",
            reagent_lots: [],
            protocol: 2,
            is_internal_control: 0,
            lab_notes: "",
        }
    }
}

function setClinicalParams(){
    return {
        [i18n.t('test.clinical.hormonotherapy')]: ["No", "Ongoing", "Planned"],
        [i18n.t('test.clinical.diabetes')]: [true, false],
        [i18n.t('test.clinical.tobacco')]: [true, false],
        [i18n.t('test.clinical.chemotherapy')]: [true, false]
    }
}

export let clinicalParameters = setClinicalParams()

i18n.on('loaded languageChanged', () => {
    clinicalParameters = setClinicalParams()
})

export function updateData(data: any){
    data['diabetes'] = data['Diabetes']
    data['tobacco'] = data['Tobacco']
    data['chemo'] = data['Chemotherapy']
    data['hormono'] = data['Hormonotherapy']
    return data
}