// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
// sections
import { LoginForm } from '../sections/auth/login';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {

  const { t, i18n } = useTranslation();

  window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function(event) {
      window.history.pushState(null, document.title, window.location.href);
  });

  return (
    <Page title="Login">
      <RootStyle>
        <Container maxWidth="xl">
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              {t('login.title')}
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>{t('login.description')}</Typography>

            <LoginForm />

          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
