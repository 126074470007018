import { useEffect, useRef, useState } from 'react';
// material
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, Stack, IconButton } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
import { useTranslation } from 'react-i18next';
import { httpClient } from '../..';

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'en',
    label: 'English',
    icon: '/static/icons/ic_flag_en.svg',
  },
  {
    value: 'fr',
    label: 'French',
    icon: '/static/icons/ic_flag_fr.svg',
  },
];

// ----------------------------------------------------------------------

type Props = {
  pk: Number | undefined
}

export default function LanguagePopover({pk}: Props) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [t, i18n] = useTranslation();

  useEffect(() => {
    if (pk !== undefined){
      httpClient.get(`/users/${pk}/`).then((res) => {
        if (res.data){
          i18n.changeLanguage(res.data?.app_language)
        }
      })
    }
  }, [pk])

  useEffect(() => {
    if (pk !== undefined){
      httpClient.patch(`/users/${pk}/`, {app_language: `${i18n.language}`})
    }
  }, [pk, i18n.language])

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (value?: string) => {
    if (value !== undefined) {
      i18n.changeLanguage(value)
    }
    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <img src={LANGS[LANGS.findIndex(lan => lan.value == i18n.language)].icon} alt={LANGS[LANGS.findIndex(lan => lan.value == i18n.language)].label} />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={() => handleClose()}
        anchorEl={anchorRef.current}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option) => (
            <MenuItem key={option.value} selected={option.value === LANGS[LANGS.findIndex(lan => lan.value == i18n.language)].value} onClick={() => handleClose(option.value)}>
              <Box component="img" alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
