import { Helmet } from 'react-helmet-async';
import React, { forwardRef } from 'react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode,
  title: string,
  other?: any,
};

const Page = forwardRef<HTMLButtonElement, Props>(({children, title='', ...other}, ref) => (
  <>
    <Helmet>
      <title>Lab App: {title}</title>
    </Helmet>

    <Box ref={ref} {...other}>
      {children}
    </Box>
  </>
));

export default Page;
