export interface Result {
    sample_id: string,
    processing_status: string,
    interpretable: boolean | undefined,
    rila_score: string,
    is_valid?: string,
    serial_number?: string,
    reference_value?: number
    quality_control?: {
        Beckman: boolean,
        NovaGray: boolean,
        IC: boolean,
        Timing: boolean
    }
}

export enum computationStatus {
    processed = "Processed",
    readyToCompute = "Ready to compute",
    calcRunning = "RILA score computation is running",
    fileSetComplete = "Files set is complete, processing"
}
