import {Box, Button, styled, Typography} from '@mui/material'
import {useDropzone} from 'react-dropzone'
import {useCallback, useState} from 'react'
import { S3 } from '../constants'
import BasicModal from './BasicModal'
import { useTranslation } from 'react-i18next';

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey[200],
    height: 200,
    borderRadius: 5,
    borderColor: theme.palette.grey[500],
    padding: 10
  }));

type Props = {
    sampleId: string | null,
    callbackFunction : (fileName?: string) => void,
    bucketName: string,
    checkFileName: Function,
    acceptRule: string,
    disabled?: boolean
}

export default function DropZone({sampleId, checkFileName, callbackFunction, bucketName, acceptRule, disabled}: Props){

    const { t, i18n } = useTranslation();
    const [isOpen, setOpen] = useState<boolean>(false)
    const [message, setMessage] = useState<string>("")

    const onDrop = useCallback((acceptedFiles: any) => {
        acceptedFiles.forEach(async (file: any) => {
            const [check1, check2] = checkFileName(file.path)
            if(check1 !== false){
                await S3.upload({
                    Bucket: bucketName,
                    Key: `${sampleId}/${file.name}`,
                    Body: file
                }).promise().then(() => {
                    callbackFunction? callbackFunction(file.name) : null
                })
            } else { 
                setOpen(true)
                setMessage(t('test.fcsFilesTab.dropzone.uploadFailed', {filePath: file.path, check2: check2}))
            }
        });
      }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, disabled: disabled})

    return (
        <>
        <StyledBox {...getRootProps()} border={1}>
            <input {...getInputProps()} accept={acceptRule}/>
            {
                disabled === true ?
                    <p>{t('test.fcsFilesTab.dropzone.linkExists')}</p> : 
                    isDragActive ?
                    <p>{t('test.fcsFilesTab.dropzone.dropHere')}</p> :
                    <p>{t('test.fcsFilesTab.dropzone.dragOrClick')}</p>
                }  
        </StyledBox>
        <BasicModal isOpen={isOpen} setOpen={setOpen} children={
            <Box>
                <Typography mb={2}>{message}</Typography>
                <Box display="flex" justifyContent="flex-end">
                    <Button variant='contained' onClick={() => setOpen(false)}>Ok</Button>
                </Box>
            </Box>
        }/>
        </>
    )
}