import TabPanel from '../components/TabPanel';
import { AlertColor, Box, Breadcrumbs, Button, Tab, Tabs, Typography, Link } from '@mui/material';
import { FormProvider } from '../components/hook-form';
import { Reagents } from '../components/test_page_components/reagentsTab';
import Page from '../components/Page';
import { useEffect, useState } from 'react';
import ICSample from '../components/test_page_components/icSampleTab';
import { useForm } from 'react-hook-form';
import FCSFiles from '../components/test_page_components/FCSFiles';
import Results from '../components/test_page_components/resultsTab';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { httpClient } from '..';
import SnackBar from '../components/Snackbar';
import { defaultValues } from '../helpers/internalControl.helper'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { fDate } from '../utils/formatDate';
import { CustomModal, checkLastModified } from "../helpers/tests.helper"
import { useTranslation } from 'react-i18next';

type Props = {
    buckets: {
      incomings: string,
      digested: string
    } | undefined,
    pk: number | undefined
}

export default function InternalControl({buckets, pk}: Props){

    const { id } = useParams();
    const snackBarDefaultValues = {
        open: false,
        severity: "error" as AlertColor,
        message: ""
    }
    
    const [tab, setTab] = useState<number>(0)
    const { t, i18n } = useTranslation();
    const [openSnackbar, setSnackbar] = useState(snackBarDefaultValues)
    const [lastModified, setLastModified] = useState<string>("")
    const [openReloadModal, setReloadModal] = useState<boolean>(false)

    const location = useLocation()
    const navigate = useNavigate()

    const [oldFormValues, setOldFormValues] = useState(defaultValues(pk === undefined? 1 : pk))

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        criteriaMode: "firstError",
        shouldFocusError: true,
        defaultValues: defaultValues(pk === undefined? 1 : pk),
    });
    const { control, register, handleSubmit, getValues, watch, reset, setValue} = methods;

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue === 3){
            checkLastModified(lastModified, id).then((isModified) => {
              if (isModified) { setReloadModal(true) }
            })
        }
        setTab(newValue)
    }

    useEffect(() => {
        if (lastModified !== ""){
          let interval = setInterval(() => {
            checkLastModified(lastModified, id).then((isModified) => {
                if(isModified){
                  setReloadModal(true)
                }
            })
          }, 1000*60)
          return () => clearInterval(interval);
        }
    }, [lastModified])

    useEffect(() => {
        reset(defaultValues(pk === undefined? 1 : pk))
        if (id !== undefined){
          httpClient.get(`tests/${id}/`).then((response) => {       
            reset(response.data)
            setOldFormValues(response.data)
            setLastModified(response.data['blood_sample']['modified'])
          })
        } else {
            let sampleId = `CI${fDate(new Date())}`
            httpClient.get(`internal_controls/${sampleId}/exists`).then((response) => {
                if (response.data !== false){
                    setValue('blood_sample.sample_id', response.data)
                } else { setValue('blood_sample.sample_id', sampleId) }
            })
        }
      }, [location])

    const onSubmit = (data: any) => {
        setSnackbar(snackBarDefaultValues)
        if (id === undefined){
            httpClient.post('/internal_controls/', data).then((res) => {
                if (res.data?.blood_sample?.non_field_errors){
                    setSnackbar({ open: true, severity: "error", message: t('internalControls.messages.icExists', {date: new Date().toLocaleDateString()})})
                }
                if (res.status === 201){
                    navigate(`/dashboard/internal-control/${res.data}`)
                }
            })
        }else{
            checkLastModified(lastModified, id).then((isModified) => {
                if(!isModified){
                    let toPatch = {} as any
                    for (let [key, value] of Object.entries(oldFormValues)){
                        if (key === "blood_sample"){
                            for (let [bsKey, bsValue] of Object.entries(oldFormValues.blood_sample)){
                                if(bsValue !== data[key][bsKey]){
                                toPatch[bsKey] = data[key][bsKey]
                                }
                            }
                        } else {
                            if (value !== data[key]){
                                toPatch[`${key}`] = data[key]
                            }
                        }
                    }
                    httpClient.patch(`tests/${id}/`, toPatch).then((response) => {
                        response.status === 200 ? 
                        setSnackbar({ open: true, severity: "success", message: t('test.messages.updateSuccess')}) :
                        setSnackbar({ open: true, severity: "error", message: t('test.messages.updateFailed')})
                    })
                } else {
                    setReloadModal(true)
                }
            })
        }
    }

    return(
        <Page title="New Internal Control">
            <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                <Link underline="hover" color="inherit" href="/dashboard/app">{t('internalControls.breadcrumb.dashboard')}</Link>
                <Typography>{t('internalControls.breadcrumb.internalControl')}</Typography>
            </Breadcrumbs>
            <Typography variant="h4">{t('internalControls.newTestTitle')}</Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Tabs value={tab} onChange={handleTabChange} centered sx={{
                    '& .MuiTab-root.Mui-selected': { backgroundColor: 'white', borderRadius: 1}, '& .MuiTab-root': {marginRight: 1, marginLeft: 1}}}>
                    <Tab label={t('test.tabs.sample', 'Sample')}/>
                    <Tab label={t('test.tabs.reagents', 'Reagents')} />
                    <Tab label={t('test.tabs.fcsFiles', 'FCS Files')} disabled={watch('blood_sample.sample_id') === "" ? true : false}/>
                    <Tab label={t('test.tabs.results', 'Results')} disabled={id === undefined ? true : false}/>
                </Tabs>
                <Box sx={{backgroundColor: 'white', borderRadius: 1}}>
                    <TabPanel value={tab} index={0}>
                        <ICSample register={register} control={control} getValues={getValues}/>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <Reagents getValues={getValues} setValue={setValue}/>
                    </TabPanel>
                    <TabPanel value={tab} index={2}>
                        <FCSFiles sampleId={getValues('blood_sample.sample_id')} incomingsBucket={buckets?.incomings}/>
                    </TabPanel>
                    <TabPanel value={tab} index={3}>
                        <Results id={id} buckets={buckets} sampleId={getValues('blood_sample.sample_id')} setLastModified={setLastModified}/>
                    </TabPanel>
                    <Box display="flex" justifyContent="flex-end" mr={3}>
                        <Button variant="outlined" sx={{marginRight: 2}}>{t('common.buttons.cancelBtn')}</Button>
                        <Button type="submit" variant='contained'>{t('common.buttons.saveBtn')}</Button>
                    </Box>
                </Box>
            </FormProvider>
            <CustomModal 
                open={openReloadModal} 
                setOpen={setReloadModal} 
                message={t('test.messages.sampleModified')}
                onClickRight={() => window.location.reload()} 
                onClickLeft={() => setReloadModal(false)} 
                rightButton={t('common.buttons.reloadBtn')} 
                leftButton={t('common.buttons.cancelBtn')}/>
            {openSnackbar.open && <SnackBar open={openSnackbar.open} severity={openSnackbar.severity} message={openSnackbar.message}/>}
        </Page>
    )
}