import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2
};

type Props = {
  isOpen: boolean,
  setOpen: any,
  children: JSX.Element
}

export default function BasicModal({isOpen, setOpen, children}: Props) {

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={() => setOpen(false)}
      >
        <Box sx={style}>
          {children}
        </Box>
      </Modal>
    </div>
  );
}