import { handlePreviousData, defaultValues, clinicalParameters, updateData} from '../components/tests/rilaBreastTest'
import Test from '../components/Test'
// ----------------------------------------------------------------------

type Props = {
  buckets: {
    incomings: string,
    digested: string
  } | undefined,
  pk: number | undefined
}

export default function RILABreastTest({buckets, pk}: Props) {
  return <Test 
      buckets={buckets}
      pageTitle="Rila Breast Test"
      testType="rilaBreastTest"
      clinicalParameters={clinicalParameters}
      defaultValues={defaultValues(pk === undefined? 1 : pk)}
      handlePreviousData={handlePreviousData}
      updateData={updateData} />
}