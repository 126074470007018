import PropTypes from 'prop-types';
// icons
import { Icon, IconifyIcon } from '@iconify/react';
// @mui
import { Box, SxProps, Theme } from '@mui/material';

// ----------------------------------------------------------------------

type Props = {
  icon: string | IconifyIcon,
  sx?: SxProps<Theme> | undefined,
  width?: number,
  height?: number,
  other?: any,
};

export default function Iconify({ icon, width, height, sx, ...other }: Props) {
  return <Box component={Icon} icon={icon} sx={{ width, height, ...sx }} {...other} />;
}
