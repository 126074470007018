import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { httpClient } from '../..';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 70;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

interface CustomerDetails {
  name: string, 
  buckets: {
    incomings: string,
    digested: string
  },
  pk: number
}

interface UserDetails {
  pk: number,
  email: string, 
  first_name: string,
  last_name: string,
}

type Props = {
  setUserDetails: Dispatch<SetStateAction<UserDetails | undefined>>,
  setCustomerDetails: Dispatch<SetStateAction<CustomerDetails | undefined>>
}

export default function DashboardLayout({setUserDetails, setCustomerDetails}: Props) {
  const [open, setOpen] = useState(false);
  const [userName, setUserName] = useState("")
  const [userPk, setUserPk] = useState<Number | undefined>(undefined)

  useEffect(() => {
    httpClient.get('pks').then((res) => {
      if(res.status === 200){
        setUserPk(res.data.user_pk)
        httpClient.get(`customers/${res.data.customer_pk}/`).then((customerResponse) => {
          let customerDetails = customerResponse.data
          customerDetails.pk = res.data.customer_pk
          setCustomerDetails(customerDetails)
          
        })
        httpClient.get(`users/${res.data.user_pk}/`).then((userResponse) => {
          setUserDetails(userResponse.data)
          setUserName(userResponse.data.first_name + " " + userResponse.data.last_name)
        })
      }
    })
  }, [])

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} userName={userName} pk={userPk}/>
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
