import { Box, FormControl, FormHelperText, FormLabel, Grid, MenuItem, Select, Stack, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { Controller } from "react-hook-form"
import { httpClient } from "../.."
import { BasicDateTimePicker } from "../DatePick"
import { useTranslation } from 'react-i18next';

type Props = {
    register: any,
    control: any,
    getValues: any
}

interface ICBatch {
    pk: number,
    batch_id: string
}

export default function ICSample({register, control, getValues}: Props){
    const [users, setUsers] = useState<Object[]>([])
    const [icBatches, setIcBatches] = useState<ICBatch[]>([])
    const { t, i18n } = useTranslation();

    useEffect(() => {
        httpClient.get('/internal_controls/ids').then((response) => { setIcBatches(response.data.results)})
        httpClient.get('/users/').then((response) => { setUsers(response.data.results)})
    }, [])

    return (
        <Box sx={{
            '& .MuiOutlinedInput-root': { width: {xs: '20ch', sm: '25ch', md: '30ch', xl: '40ch' }},
        }}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Stack spacing={2} direction="column" justifyContent="space-evenly" alignItems="flex-start">
                        <FormLabel required>{t('test.sampleTab.operatedBy')}</FormLabel>
                        <Controller control={control} name="blood_sample.lab_reception_user" rules={{ required: t('test.sampleTab.validation.operatedBy')}} render={({field, fieldState: {error}}) => (
                            <FormControl error={error? true : false}>
                                <Select size='small'name='blood_sample.lab_reception_user' value={users.length ? field.value: ""} onChange={(value) => field.onChange(value)}>
                                    {users.map((user: any, index) => {
                                        return (
                                            <MenuItem key={index} value={user.pk}>{user.first_name} {user.last_name}</MenuItem>
                                        )
                                    })}
                                </Select>
                                <FormHelperText>{error?.message}</FormHelperText>
                            </FormControl>
                        )} />

                        <FormLabel required>{t('test.sampleTab.cellCultureStartTime')}</FormLabel>
                        <Controller control={control} name="blood_sample.cell_culture_start_time" rules={{ required: t('test.sampleTab.validation.cellCultureStartTime') }} render={({field, fieldState: {error}}) => (
                            <BasicDateTimePicker field={field} isError={error}/>
                        )}  />

                        <FormLabel required>{t('test.sampleTab.cellCultureEndTime')}</FormLabel>
                        <Controller control={control} name="blood_sample.cell_culture_end_time" rules={{ required: t('test.sampleTab.validation.cellCultureEndTime')}} render={({field, fieldState: {error}}) => (
                            <BasicDateTimePicker field={field} isError={error}/>
                        )}  />

                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack spacing={2} direction="column" justifyContent='space-evenly' alignItems="flex-start">
                        <FormLabel required>{t('internalControls.test.icSerial')}</FormLabel>
                        <Typography>{`${getValues('serial_number')}`}</Typography>
                        <FormLabel required>{t('internalControls.test.icBatch')}</FormLabel>
                        <Controller control={control} name="internal_control_batch_id" rules={{ required: t('internalControls.validation.batchId') }} render={({field, fieldState: {error}}) => (
                            <FormControl error={error? true : false}>
                                <Select size='small' value={icBatches.length ? (field.value === undefined ? "" : field.value) : ""} onChange={(value) => field.onChange(value)}>
                                    {icBatches.map((batch, index) => {
                                        return (
                                            <MenuItem key={index} value={batch.pk}>{batch.batch_id}</MenuItem>
                                        )
                                    })}
                                </Select>
                                <FormHelperText>{error?.message}</FormHelperText>
                            </FormControl>
                        )} />
                        <FormLabel>Sample Notes</FormLabel>
                        <TextField multiline {...register("blood_sample.notes")} rows={3}/>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    )
}