import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, SxProps, Theme } from '@mui/material';

// ----------------------------------------------------------------------

type Props = {
  disabledLink?: boolean,
  sx?: SxProps<Theme>,
};

export default function Logo({ disabledLink = false, sx }: Props) {

  // OR
  const logo = <Box component="img" src="/static/logo.png" sx={{ width: 40, height: 40, display: 'inline' ,...sx }} />
  const logoText = <Box component="img" src="/static/logo-text.png" sx={{ height: 35, display: 'inline', marginLeft: 2, marginBottom: -1 ,...sx}} />

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}{logoText}</RouterLink>;
}
