// scroll bar
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './i18n';

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import axios, { AxiosRequestConfig } from "axios";
import { getIdToken, logout } from './sections/auth/Authentication';

// ----------------------------------------------------------------------

export const API_URL = process.env.NODE_ENV === "development" ? "https://labapp-api.dev.aws.nova-gray.com/api/v1/" : "https://labapp-api.prod.aws.nova-gray.com/api/v1/"
export const ENV: any = process.env.NODE_ENV === "development" ? "local" : "prod"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export const httpClient = axios.create({
  baseURL: API_URL,
});

httpClient.interceptors.request.use(async (config :AxiosRequestConfig) => {
  const token = await getIdToken()
  if (config.headers !== undefined){
    config.headers['Authorization'] = `Bearer ${token}`
    return config
  }
  }, (error) => {
    console.log(error)
  }
)

httpClient.interceptors.response.use((response) => {
  return response
  },(error) => {
    console.log(error.response.status)
  if (error.response.status === 401) {
    logout()
  } else {
    console.group("Error");
    console.log(error);
    console.groupEnd();
    return error.response
  }
});

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HelmetProvider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
