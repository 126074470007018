import {Container, Typography, Breadcrumbs, Link, Box, Modal} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Page from '../components/Page';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { httpClient } from '..';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2
};

export default function Contact() {
  const { t, i18n } = useTranslation();
  const [isOpen, setOpen] = useState<boolean>(false)
  const [image, setImage] = useState()

  const getLabel = (test: string) => {
    httpClient.get(`label/${test}`).then((res) => {
      setImage(res.data)
    }).finally(() => setOpen(true))
  }

  return (
    <Page title="MultiParametric Breast Test">
      <Container maxWidth="xl">
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
          <Link underline="hover" color="inherit" href="/dashboard/app">Dashboard</Link>
          <Typography color="text.primary">Contact Us</Typography>
        </Breadcrumbs>
        <Typography variant="h4">
          Contact Us
        </Typography>
        <Box mt={3}>
          <Typography>
            {t('contact.contactMessage')}< br/>
            <Link variant="subtitle2" underline="hover" href="mailto:support@nova-gray.com">
              support@nova-gray.com
            </Link>
          </Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="h5" mb={2}>CE labels</Typography>
          <Link variant="subtitle2" onClick={() => getLabel("breast")}>RILA Breast Test</Link><br/>
          <Link variant="subtitle2" onClick={() => getLabel("prostate")}>RILA Prostate Test</Link>
        </Box>
        <Modal open={isOpen} onClose={() => setOpen(false)}>
          <Box sx={style}>
            <img src={`data:image/jpeg;base64,${image}`}></img>
          </Box>
        </Modal>
      </Container>
    </Page>
  );
}