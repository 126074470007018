import { Box, Button, Checkbox, FormLabel, Grid, List, ListItem, ListItemText, MenuItem, Paper, useTheme, Select, Stack, TextField, Typography, ListItemButton } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { httpClient } from '../../index'
import BasicModal from '../BasicModal';
import { useParams } from "react-router-dom";
import {ReagentLot, intersection, not} from "../../helpers/reagent.helper"
import { useTranslation } from 'react-i18next';

export function Reagents({getValues, setValue}: any){

    const isMounted = useRef(false)
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const [reagents, setReagents] = useState<Object[]>([])
    const [reagentLots, setReagentLots] = useState<ReagentLot[]>([])
    const [lastUsedReagents, setLastUsedReagents] = useState<number[]>([])
    const [checked, setChecked] = useState<ReagentLot[]>([]);
    const [left, setLeft] = useState<ReagentLot[]>([]);
    const [right, setRight] = useState<ReagentLot[]>([]);

    const defaultNewReagentBatchValues = {
        reagent: "",
        batch_id: ""
    }
    const [newReagentBatch, setNewReagentBatch] = useState(defaultNewReagentBatchValues)
    const [open, setOpen] = useState<boolean>(false)

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    const theme = useTheme()
  
    const handleToggle = (value: ReagentLot) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };
  
    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };
  
    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };
  
    const handleAllLeft = () => {
      setLeft(left.concat(right));
      setRight([]);
    };

    useEffect(() => {
        httpClient.get('reagent_lots/last_used/').then((response) => {
            setLastUsedReagents(response.data)
            httpClient.get(`/reagent_lots/?enabled=True`).then((res) => {
                setReagentLots(res.data.results)
                if (id === undefined && getValues('blood_sample.reagent_lots').length === 0){
                    setList(response.data, res.data.results)
                } else setList(getValues('blood_sample.reagent_lots'), res.data.results)
            })
        })
        httpClient.get('/reagents').then((response) => {
            setReagents(response.data.results)
        })
    }, [open])

    useEffect(() => {
        if(isMounted.current){
            setValue("blood_sample.reagent_lots", right.map(o => o.pk))
        } else {
            isMounted.current = true
        }
    }, [right])

    const handleSave = () => {
        httpClient.post('/reagent_lots/', newReagentBatch).then((response) => {
            setNewReagentBatch(defaultNewReagentBatchValues)
            setOpen(false)
        })
    }

    const setList = (chosenReagents: number[], reagentBatches: ReagentLot[] = reagentLots) => {
        let preSelectedReagentLots: ReagentLot[] = []
        let otherThanSelected: ReagentLot[] = []
        reagentBatches.forEach((lot: ReagentLot) => {
            chosenReagents.includes(lot.pk) ? preSelectedReagentLots.push(lot) : otherThanSelected.push(lot)
        })
        setRight(preSelectedReagentLots)
        setLeft(otherThanSelected)
    }

    const customList = (items: Object[]) => (
        <Paper sx={{ width: {lg: 340, xl: 510}, height: 500, overflow: 'auto', border: 1, borderColor: theme.palette.grey[200]}}>
          <List dense component="div" role="list">
            {items.map((value: any) => {
                const labelId = `transfer-list-item-${value.pk}-label`;
                return (
                    <ListItemButton key={value.pk} role="listitem" onClick={handleToggle(value)} selected={checked.indexOf(value) !== -1} sx={{height: 45}}>
                        <ListItemText id={labelId} primary={`${value.name} | ${value.batch_id}`} />
                    </ListItemButton>
                );
                })}
            <ListItem />
          </List>
        </Paper>
      );

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" mb={1} alignItems="baseline">
                <Typography ml={2} mb={2} variant="h6">{t('test.reagentsTab.reagentsUsed')}</Typography>
                <Box>
                    <Button variant="outlined" sx={{marginRight: 1}} onClick={() => setList(lastUsedReagents)}>{t('test.reagentsTab.copyLast')}</Button>
                    <Button variant="outlined" onClick={() => setOpen(true)}>{t('test.reagentsTab.newBatch')}</Button>
                </Box>
            </Box>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item>{customList(left)}</Grid>
                <Grid item mr={1}>
                    <Grid container direction="column" alignItems="center">
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllLeft}
                        disabled={right.length === 0}
                        aria-label="move all left"
                    >
                        ≪
                    </Button>
                    </Grid>
                </Grid>
                <Grid item ml={1}>{customList(right)}</Grid>
            </Grid>
            <BasicModal isOpen={open} setOpen={setOpen} children={
                <Box>
                <Typography variant="subtitle1">{t('test.reagentsTab.newBatchModal.title')}</Typography>
                <Stack direction="column">
                    <FormLabel sx={{marginTop: 1}}>{t('test.reagentsTab.newBatchModal.reagent')}</FormLabel>
                    <Select size="small" value={newReagentBatch.reagent} onChange={(event) => setNewReagentBatch({...newReagentBatch, ['reagent']: event.target.value})}>
                        {reagents.map((reagent: any, index) => {
                            return (
                                <MenuItem key={index} value={reagent.pk}>{reagent.name}</MenuItem>
                            )
                        })}
                    </Select>
                    <FormLabel sx={{marginTop: 2}}>{t('test.reagentsTab.newBatchModal.batchId')}</FormLabel>
                    <TextField size="small" name="batch_id" value={newReagentBatch.batch_id} onChange={(event) => setNewReagentBatch({...newReagentBatch, ['batch_id']: event.target.value})} />
                    <Box display="flex" justifyContent="flex-end" m={1}><Button variant="contained" onClick={handleSave}>{t('common.buttons.saveBtn')}</Button></Box>
                </Stack>
            </Box>
            }/>
        </Box>
    )
}
