export const defaultValues = (pk: number) => {
  return {
        test_type: "NGIC",
        test_id: null,
        serial_number: "-",
        test_serial: "",
        internal_control_batch_id: "",
        blood_sample: {
          sample_id: "",
          customer: pk,
          internal_control: "",
          sample_datetime: new Date().toISOString().split('.')[0] + 'Z',
          cell_culture_start_time: null,
          cell_culture_end_time: null,
          lab_reception_user: "",
          lab_reception_form: false,
          usable_sample: 0,
          notes: "",
          reagent_lots: [],
          protocol: 2,
          is_internal_control: 0,
          lab_notes: "",
      }
    }
}

export interface InternalControl {
  test_serial: string,
  serial_number: string,
  cell_culture_start_time: string,
  internal_control_batch_id: number
}
