import { AlertColor } from "@mui/material"

export function checkFileName(fileName: string){
    if (fileName.endsWith('.pdf') === false){ return [false, 'File type is not allowed']}

    let type = ""
    if (fileName.includes('STD')){
      type = "STD"
    } else if (fileName.includes('QC')) {
      type = "QC"
    } else { return [false, 'Quality Control type is undefined.']}

    let fullDate = fileName.split('_').pop()?.split('.')[0]
    if (fullDate === undefined) { return [false, 'Date is undefined'] }

    const date = `${fullDate?.substring(0,4)}-${fullDate?.substring(4,6)}-${fullDate?.substring(6,8)}`
    return [type, date]
  }

export function qualityControlsFileHandler(data: Object[]){
    let files: Object[] = []
    const key = 'Key' as string

    if (data.length){
      data.forEach((row) => {
        const fileName = row[key as keyof typeof row].toString().split('/')[1]
        const [type, date] = checkFileName(fileName)
        
        Object.assign(row, {fileName, type, date})
        files.push(row)
      })
    }
    return files
}

export const snackBarDefaultValues = {
    open: false,
    severity: "error" as AlertColor,
    message: ""
  }