import { AlertColor } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { S3 } from '../constants'

export function downloadBlob(blob: Blob | MediaSource, name = `$.pdf`) {
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = name;
    document.body.appendChild(link);
    link.dispatchEvent(
    new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
    })
    );
    document.body.removeChild(link);
}

export const handleDownload = (e: { preventDefault: () => void; }, fileName: string, bucketName: string, setSnackbar: Dispatch<SetStateAction<{open: boolean, severity: AlertColor, message: string}>>) => {
    e.preventDefault();
    
    if (bucketName !== undefined){
      const params = {
        Bucket: bucketName,
        Key: `${fileName}`,
      };

      S3.getObject(params, (err, data) => {
        if (err) {
          setSnackbar({open: true, severity: "error", message: "Failed to download report" })
        } else {
          let pdfBlob = new Blob([data.Body as BlobPart], {
            type: 'application/pdf',
          });
          downloadBlob(pdfBlob, `${fileName}`);
        }
      });
    }
  };