export interface ReagentLot {
    batch_id: string,
    customer: number,
    description: string,
    enabled: boolean,
    name: string,
    pk: number,
    reagent: number,
    use: string
}

export const intersection = (a: ReagentLot[], b: ReagentLot[]) => a.filter((value) => b.indexOf(value) !== -1);

export const not = (a: ReagentLot[], b: ReagentLot[]) => a.filter((value) => b.indexOf(value) === -1);
