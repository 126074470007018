import { alpha, styled } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";

const ODD_OPACITY = 0.2;

export const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
  },
  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY)
  },
  '& .MuiDataGrid-row': {
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
    }
  },
  '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
    '&:focus, &:focus-within, &.Mui-focused': {
      outline: "none",
    }
  },
}));