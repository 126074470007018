// material
import { Box, Button, Container, Typography, Link, Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';
// components
import Page from '../components/Page';
import { httpClient } from '../index'
import { StripedDataGrid } from '../components/StripedDataGrid'
import { useTranslation } from 'react-i18next';
import { GridColDef } from '@mui/x-data-grid';
// ----------------------------------------------------------------------

export interface UserDetails {
  pk: number,
  email: string, 
  first_name: string,
  last_name: string,
}

type Props = {
  userDetails: UserDetails | undefined
  customerName: string | undefined
}

export default function User({userDetails, customerName}: Props) {

  const { t, i18n } = useTranslation();
  const [users, setUsers] = useState<Object[]>([])
  const [nbUsers, setNbUsers] = useState<number>()
  const [loading, setLoading] = useState(false);

  const columns: GridColDef[] = [
    { field: 'name', headerName: t('user.columns.name'), flex: 2, minWidth: 200},
    { field: 'email', headerName: t('user.columns.email'), flex: 2, minWidth: 200},
    { field: 'enabled', headerName: t('user.columns.active'), flex: 1, minWidth: 50, renderCell: (param) => (
      <Checkbox  checked={param.value} disabled={true}/>
    )},
  ];

  useEffect(() => {
    setLoading(true)
    httpClient.get('pks').then((response) => {
      if (response.status === 200){
        httpClient.get(`customers/${response.data.customer_pk}/`).then((customerResponse) => {
          setNbUsers(customerResponse.data.users.length)
          setUsers(customerResponse.data.users)
        })
      }
    }).finally(() => setLoading(false))
  }, [])

  return (
    <Page title="User">
      <Container maxWidth="xl">
          <Typography variant="h4" mb={2}>
            {t('user.title')}
          </Typography>
          <Box display="flex" justifyContent="flex-end" mt={1}>
            <Button variant='outlined'>
              <Link variant="subtitle2" underline="none" href="mailto:support@nova-gray.com?subject=Please change my settings"> {t('user.askForChangeBtn')}
              </Link>
            </Button>
          </Box>
          <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 6fr', columnGap: 3, rowGap: 1, marginBottom: 2}}>
              <Typography>{t('user.userName')}</Typography>
              <Typography> {userDetails?.first_name} {userDetails?.last_name} </Typography>
              <Typography>{t('user.email')}</Typography>
              <Typography> {userDetails?.email} </Typography>
              <Typography>{t('user.institution')}</Typography>
              <Typography>{customerName}</Typography>
          </Box>
          <Typography variant="h5" mt={1} mb={2}>{t('user.institutionUsers')}</Typography>
          <StripedDataGrid 
            columns={columns}
            rows={users}
            rowCount={nbUsers}
            pageSize={10}
            getRowId={(row) => row.email}
            rowsPerPageOptions={[10]}
            autoHeight
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            disableSelectionOnClick
            loading={loading}
          />
      </Container>
    </Page>
  );
}
