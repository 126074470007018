// component
import i18n from '../../i18n'
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import WhereToVoteTwoToneIcon from '@mui/icons-material/WhereToVoteTwoTone';
import OpacityTwoToneIcon from '@mui/icons-material/OpacityTwoTone';
import BloodtypeTwoToneIcon from '@mui/icons-material/BloodtypeTwoTone';
import FactCheckTwoToneIcon from '@mui/icons-material/FactCheckTwoTone';
import SupportAgentTwoToneIcon from '@mui/icons-material/SupportAgentTwoTone';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
// ----------------------------------------------------------------------

export default function setNavConfig(){
  return [
    {
      title: i18n.t('common.navBar.dashboard', 'Dashboard'),
      path: '/dashboard/app',
      icon: <DashboardTwoToneIcon />,
    },
    {
      title: i18n.t('common.navBar.internalControl', 'Internal Control'),
      path: '/dashboard/internal-control',
      icon: <WhereToVoteTwoToneIcon />,
    },
    {
      title: i18n.t('common.navBar.rila', 'RILA'),
      path: '#',
      icon: <OpacityTwoToneIcon />,
      children: [
        {
          title: i18n.t('common.navBar.breastTest', 'Breast Test'),
          path: '/dashboard/rila-breast-test',
          icon: <OpacityTwoToneIcon />,
        },
        {
          title: i18n.t('common.navBar.prostateTest', 'Prostate Test'),
          path: '/dashboard/rila-prostate-test',
          icon: <OpacityTwoToneIcon />,
        }
      ]
    },
    {
      title: i18n.t('common.navBar.multiParametric', 'Multi-Parametric'),
      path: '#',
      icon: <BloodtypeTwoToneIcon />,
      children: [
        {
          title: i18n.t('common.navBar.breastTest', 'Breast Test'),
          path: '/dashboard/multi-param-breast-test',
          icon: <BloodtypeTwoToneIcon />,
        },
        {
          title: i18n.t('common.navBar.prostateTest', 'Prostate Test'),
          path: '/dashboard/multi-param-prostate-test',
          icon: <BloodtypeTwoToneIcon />,
        }
      ]
    },
    {
      title: i18n.t('common.navBar.qualityControl', 'Quality Control'),
      path: '/dashboard/quality-control',
      icon: <FactCheckTwoToneIcon />,
    },
    {
      title: i18n.t('common.navBar.contactUs', 'Contact Us'),
      path: '/dashboard/contact',
      icon: <SupportAgentTwoToneIcon />,
    },
    {
      title: i18n.t('common.navBar.logout', 'Logout'),
      path: '/login',
      icon: <LogoutTwoToneIcon />,
    }
  ];
}

